var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"bTq3uz15HeUsxunymkq1B"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const shouldEnableSentry = process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  sampleRate: 1.0, // sample rate for errors
  tracesSampler: (samplingContext) => {
    switch (true) {
      case samplingContext.transactionContext.name.includes('confirmation'):
        return 1.0; // we want to track all instances of users reaching confirmation page
      default:
        return 0.1; // sampling for all other transactions
    }
  },
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  enabled: shouldEnableSentry,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
